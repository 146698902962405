import React from 'react';
import styled from '@emotion/styled';
import { fontBold, textUpperCase } from './Styled';
import { colors } from '../utilities/themeVars';

const InputWrapper = styled.div`
  label {
    display: block;
    ${[textUpperCase, fontBold]};
    color: ${colors.primary};
    font-size: 12px;
    letter-spacing: 1px;
    margin-bottom: 6px;
  }

  input {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    padding: 10px 10px;
    width: 100%;

    &:focus {
      outline: none;
      background: #f7f7f7;
    }
  }
`;

const FormInput = ({ label, placeholder, value, handleInputChange, name }) => {
  return (
    <InputWrapper>
      <label htmlFor={name}>{label}</label>
      <input
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        name={name}
      />
    </InputWrapper>
  );
};

export default FormInput;
