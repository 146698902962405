import FormInput from "./FormInput";
import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { StoreContext } from "../store";
import { navigate } from "gatsby-link";

const StyledFormWrap = styled.div`
  .explore-input {
    margin-bottom: 20px;
  }
`;

const FormExploreArt = ({ drupalId }) => {
  const { exploredArt, markArtExplored } = useContext(StoreContext);

  const [formState, setFormState] = useState({
    artistName: "",
    artTitle: "",
    date: "",
    feelings: "",
  });

  useEffect(() => {
    if (exploredArt && exploredArt[drupalId]) {
      setFormState({ ...exploredArt[drupalId] });
    }
  }, []);

  // map the labels to the fields
  // defined above
  const inputLabels = [
    {
      placeholder: "Artist name",
      label: "Artist",
    },
    {
      placeholder: "Title of Artwork",
      label: "Title",
    },
    {
      placeholder: "Date of Creation",
      label: "Date of Creation",
    },
    {
      placeholder: "How does the art make you feel?",
      label: "I feel",
    },
  ];

  const handleInputChange = (event) => {
    const target = event.target;
    setFormState({
      ...formState,
      [target.name]: target.value,
    });
  };

  const handleSubmit = (event, formState) => {
    event.preventDefault();
    markArtExplored(drupalId, formState);
    return navigate("/explore-the-broad");
  };

  return (
    <StyledFormWrap>
      <form>
        {Object.keys(formState).map((key, index) => {
          return (
            <div key={key} className="explore-input">
              <FormInput
                value={formState[key]}
                name={key}
                handleInputChange={handleInputChange}
                placeholder={inputLabels[index].placeholder}
                label={inputLabels[index].label}
              />
            </div>
          );
        })}
        <div className={"back-to-list"}>
          <button onClick={(e) => handleSubmit(e, formState)}>Done</button>
        </div>
      </form>
    </StyledFormWrap>
  );
};

export default FormExploreArt;
