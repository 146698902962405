import React, { useContext } from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import Seo from "../components/seo";
import ParentRouteHandler from "../components/ParentRouteHandler";
import { colors } from "../utilities/themeVars";
import { StoreContext } from "../store";
import FormExploreArt from "../components/FormExploreArt";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const Container = styled.div`
  padding-bottom: 50px;

  .header-image {
    margin-bottom: 15px;
  }

  .back-to-list {
    position: fixed;
    bottom: ${(props) => (props.trackUrlNotNull ? "120px" : "20px")};

    left: 0;
    right: 0;
    padding: 0 10px;

    button {
      display: block;
      width: 100%;
      background: ${colors.primary};
      color: ${colors.white};
      padding: 10px;
      text-align: center;
    }
  }
`;

const ExploreArtworkTemplate = (props) => {
  const { data } = props;
  const { audioDetails, exploredArt, markArtExplored } = useContext(
    StoreContext
  );
  const { url: trackUrl } = audioDetails;

  const { nodeArtPages: artwork } = data;
  const { drupal_id } = artwork;

  return (
    <div>
      <Seo title={artwork.title} />
      <ParentRouteHandler
        title={"Back to Explore the Broad"}
        path={"/explore-the-broad"}
      />

      <Container trackUrlNotNull={trackUrl}>
        <div className={"content"}>
          <div className="header-image">
            {artwork.rel.image ? (
              <GatsbyImage
                image={getImage(artwork.rel.image?.rel.media.localFile)}
                alt="Artwork Image"
              />
            ) : (
              <StaticImage src="../images/artwork-placeholder.jpg" height={500} width={500} alt="placeholder" />
            )}
          </div>

          <FormExploreArt drupalId={drupal_id} />
        </div>
      </Container>
    </div>
  );
};

export default ExploreArtworkTemplate;

export const query = graphql`
  query exploreArtwork($drupal_id: String!) {
    nodeArtPages(drupal_id: { eq: $drupal_id }) {
      title
      drupal_id
      rel: relationships {
        image: field_preview_sharing_image_med {
          rel: relationships {
            media: field_media_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }
`;
